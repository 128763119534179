@import "reset";

@font-face {
  font-family: 'HusqvarnaRegular';
  src: url('../fonts/HusqvarnaGothic-Regular.ttf');
}

@font-face {
  font-family: 'HusqvarnaBold';
  src: url('../fonts/HusqvarnaGothic-Bold.ttf');
}

:root {
  --base-font-size: 16px;
  --base-font-family: HusqvarnaRegular;
  --font-family-bold: HusqvarnaBold;

  --color-white: #FFF;
  --color-underlayed: rgba(44, 44, 44, 1);
  --color-underlayed-transparent: rgba(0, 0, 0, 0.7);

  --max-width: 1200px;
}

html {
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
}

html, body {
  height: 100vh;
  line-height: 1.5;
}

b, strong, em {
  font-weight: bold;
}

.container {
  max-width: var(--max-width);
}

.header__text__container {
  &:first-child {
    padding-left: 10%;
  }

  & + & {
    width: 50%;
    transform: translateX(25%);
  }
}

.text__underlayed {
  font-family: var(--font-family-bold);
  font-weight: bold;
  line-height: 1.8;
  padding: 3px 15px;
  color: var(--color-white);
  background: var(--color-underlayed);
  box-decoration-break: clone;
}

.header__text {
  @extend .text__underlayed;
  background-color: var(--color-underlayed-transparent);
}

.section {
  & > * {
    margin-left: 10%;
    margin-right: 5%;
  }
}

.section-form {
  & > * {
    $margin: 15%;
    margin-left: $margin;
    margin-right: $margin;
  }
}

.map__link {
  margin: 0;
}

.map {
  min-height: 50vh;
  pointer-events: none;
}

.map__text {
  margin-left: 10%;
}

.img-geraet {
  margin-left: 0;
  margin-right: 0;
  bottom: -6rem;
}

.img-trennschleifer {
  left: -8rem;
  bottom: -7rem;
}

.registration__grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 2.5rem;
  grid-row-gap: 1.5rem;
}
